
.accordion-body {
  div.input {
    width: 80% !important;
  }
}

input::placeholder {
  color: #fff;
}

div.input {
  display: flex;
  position: relative;
  align-items: flex-end;
  border: 0;
  background-color: rgba(0, 0, 0, 0.30);
  height: 28px;
  margin: 17px 0 3px 0;
  box-sizing: border-box;
  width: 100%;

  &.multi {
    height: auto;
  }

  &.textArea {
    height: 100px;

    textarea {
      height: 100px;
      font-size: 12px;
    }
  }

  input[type="text"],
  input[type="password"],
  input[type="email"],
  input[type="number"],
  textarea,
  select {
    position: relative;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: 0;
    outline: none;
    background-color: transparent;
    height: 28px;
    font-size: 16px;
    //color: rgba(90,90,90,1) !important;
    color: #fff;
    width: calc(100% - 2px);
    padding-left: 3px;
    margin: 0;
  }
}

input[type=number] {
  text-align: center;
  //padding-right:10px;
}

div.input > textarea {
  padding-top: 6px;
}

div.input > input[type="text"]:focus, div.input > input[type="password"]:focus, div.input > input[type="email"]:focus, div.input > textarea:focus, div.input > select:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

div.input > label {
  position: absolute;
  text-shadow: none;
  letter-spacing: 2px;
  margin: 0;
  user-select: none; /* Standard syntax */
  transition: all 1s;
  top: -10px;
  font-size: 9px;
  line-height: 8px;
  left: 0;
  color: rgba(250, 250, 250, 1);
}

option {
  background-color: rgba(0, 0, 0, 0.6);
}

div.input.populated > input[type="text"], div.input.populated > input[type="password"], div.input.populated > input[type="email"], div.input.populated > input[type="number"] {
  box-sizing: border-box;
  padding-left: 6px;
}

div.input.populated > input[type="number"] {
  padding-top: 5px;
  padding-left: 6px;
  box-sizing: border-box;
}

div.checkbox {
  padding-bottom: 8px;

  img {
    width: 26px;
  }
}
input[type=checkbox]{
  position:relative;
  box-sizing: border-box;
  padding:0;
}

input[type=checkbox]:after{
  content: " ";
  position:absolute;
  background-color:#000;
  color:#fff;
  margin-top:-2px;
  border:1px solid rgba(180,180,180,1);
  width:16px;
  height:14px;
  top:0;
  left:0;
  display:flex;
  align-items: center;
  justify-content: center;
}

input[type=checkbox]:checked:after{
  content: "\2713";
}
